import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AssetType } from '@remento/types/asset';
import { EntityType } from '@remento/types/entity';
import { PromptType } from '@remento/types/project';

import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { toast } from '@/components/RMToast/RMToast.tsx';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { OnboardingPromptsReview } from '@/modules/onboarding/components/OnboardingPromptsReview/OnboardingPromptsReview';
import { OnboardingPromptsReviewMobile } from '@/modules/onboarding/components/OnboardingPromptsReview/OnboardingPromptsReview.mobile';
import { OnboardingPromptDraftListContainer } from '@/modules/onboarding/containers/OnboardingPromptDraftList.container';
import { OnboardingPromptEditContainer } from '@/modules/onboarding/containers/OnboardingPromptEdit.container';
import { useOnboardingContext } from '@/modules/onboarding/onboarding.context';
import {
  addPromptDraft,
  getPromptDrafts,
  resetPromptDrafts,
  usePromptDraftIds,
} from '@/modules/project/states/prompt-drafts.state';
import { usePromptDraftsStore } from '@/modules/project/states/prompt-drafts.state.context';
import {
  getSetupPromptsFrequencyPath,
  getSetupPromptTypeSelectionPath,
  getSetupTextPromptSelectionPath,
} from '@/modules/routing';
import { useServices } from '@/Services';
import { usePersonQuery } from '@/services/api/person';
import { CreatePromptPayload, useIsCurrentUserTheStoryteller, useProjectQuery } from '@/services/api/project/index.ts';
import { captureException } from '@/utils/captureException.ts';
import { openFilestackPicker } from '@/utils/filestack';
import { secureUuid } from '@/utils/uuid';

export function SetupPromptsReviewPage() {
  const projectId = useParams().projectId ?? null;
  const isMobile = useIsMobileViewport();

  const { onboardingAnalyticsService, assetService, projectCacheService } = useServices();
  const navigate = useNavigate();
  const { promptsType } = useOnboardingContext();

  const projectQuery = useProjectQuery(projectId);
  const storytellerPersonQuery = usePersonQuery(projectQuery.data?.subjectPersonIds[0]);
  const storytellerFirstName = storytellerPersonQuery.data?.name?.first ?? null;
  const isCurrentUserTheStoryteller = useIsCurrentUserTheStoryteller(projectId);

  const promptDraftsStore = usePromptDraftsStore();
  const selectedPromptsIds = usePromptDraftIds(promptDraftsStore);

  const [editingPromptId, setEditingPromptId] = useState<string | null>(null);
  const [showGoBackConfirmation, setShowGoBackConfirmation] = useState(false);

  // Actions
  const handleGoBack = useCallback(() => {
    setShowGoBackConfirmation(true);
  }, []);

  const handleConfirmGoBack = useCallback(() => {
    if (projectId == null) {
      navigate('/');
    } else if (promptsType === PromptType.TEXT) {
      navigate(getSetupTextPromptSelectionPath(projectId));
    } else {
      navigate(getSetupPromptTypeSelectionPath(projectId));
    }
    resetPromptDrafts(promptDraftsStore);
  }, [navigate, promptDraftsStore, promptsType, projectId]);

  const handleCancelGoBack = useCallback(() => {
    setShowGoBackConfirmation(false);
  }, []);

  const handleNext = useCallback(async () => {
    try {
      if (projectId == null) {
        throw new Error('Invalid project ID');
      }
      const promptDrafts = getPromptDrafts(promptDraftsStore);
      const prompts = await Promise.all(
        promptDrafts.map(async (promptDraft): Promise<CreatePromptPayload> => {
          if (promptDraft.type === PromptType.PHOTO) {
            const asset = await assetService.createFilestackAsset({
              handle: promptDraft.photo.handle,
              type: AssetType.IMAGE,
              entity: { type: EntityType.PROMPT, id: promptDraft.id },
            });
            return {
              id: promptDraft.id,
              status: null,
              type: PromptType.PHOTO,
              imagesIds: [asset.id],
              question: promptDraft.question,
            };
          } else {
            return {
              id: promptDraft.id,
              status: null,
              type: PromptType.TEXT,
              question: promptDraft.question,
              template: promptDraft.template,
            };
          }
        }),
      );
      await projectCacheService.createPrompts(projectId, prompts);
      onboardingAnalyticsService.onOnboardingPromptsReviewed(selectedPromptsIds.length);
      navigate(getSetupPromptsFrequencyPath(projectId));
    } catch (err) {
      toast('An unexpected error has occurred.', 'root-toast', 'error');
      captureException(err, true);
    }
  }, [
    assetService,
    navigate,
    onboardingAnalyticsService,
    projectCacheService,
    projectId,
    promptDraftsStore,
    selectedPromptsIds.length,
  ]);

  const handleAddMore = useCallback(async () => {
    if (promptsType !== PromptType.PHOTO) {
      console.warn('Unable to add more prompts for prompt type ' + promptsType);
      return;
    }
    try {
      await openFilestackPicker({
        accept: ['image/png', 'image/jpeg', 'image/webp'],
        maxSize: 1024 * 1024 * 20,
        maxFiles: 20,
        onUploadDone: ({ filesUploaded }) => {
          for (const photo of filesUploaded) {
            addPromptDraft(promptDraftsStore, {
              id: secureUuid(),
              type: PromptType.PHOTO,
              photo,
              question: 'What is happening in this photo?',
              edited: false,
            });
          }
        },
      });
    } catch (error) {
      captureException(error, true);
    }
  }, [promptDraftsStore, promptsType]);

  // Redirect to the previous step if no type is defined
  useEffect(() => {
    if (promptsType === null && projectId != null) {
      navigate(getSetupPromptTypeSelectionPath(projectId));
    }
  }, [navigate, projectId, promptsType]);

  return (
    <>
      {isMobile ? (
        <OnboardingPromptsReviewMobile
          isCurrentUserTheStoryteller={isCurrentUserTheStoryteller ?? false}
          storytellerFirstName={storytellerFirstName}
          nextDisabled={selectedPromptsIds.length === 0}
          showAddMore={promptsType === PromptType.PHOTO}
          onAddMore={handleAddMore}
          onNext={handleNext}
          onBack={handleGoBack}
          PromptList={<OnboardingPromptDraftListContainer onEdit={setEditingPromptId} />}
        />
      ) : (
        <OnboardingPromptsReview
          isCurrentUserTheStoryteller={isCurrentUserTheStoryteller ?? false}
          storytellerFirstName={storytellerFirstName}
          nextDisabled={selectedPromptsIds.length === 0}
          showAddMore={promptsType === PromptType.PHOTO}
          onAddMore={handleAddMore}
          onNext={handleNext}
          onBack={handleGoBack}
          PromptList={<OnboardingPromptDraftListContainer onEdit={setEditingPromptId} />}
        />
      )}

      <OnboardingPromptEditContainer
        promptDraftsStore={promptDraftsStore}
        promptId={editingPromptId}
        onClose={() => setEditingPromptId(null)}
      />

      <RMConfirmationModal
        open={showGoBackConfirmation}
        title="Discard prompts?"
        message="This action will discard your current prompts."
        confirmLabel="Discard"
        type="danger"
        onConfirm={handleConfirmGoBack}
        onCancel={handleCancelGoBack}
        onClose={handleCancelGoBack}
      />
    </>
  );
}
